body {
  font-family: 'Outfit'
}

.bg-img{
  background-image: url('../src/assets/images/backgroundwithglow.png');
  background-repeat: no-repeat; 
  background-size: cover; 
  background-position: center
}

.bg-vector {
  background-image: url('../src/assets/images/Vector7.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  height: '100%';
  width: '100%';
}

.bg-vector2 {
  background-image: url('../src/assets/images/Vector7(1).png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
}

.bg-vector3 {
  background-image: url('../src/assets/images/red border.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
}

/* .img-div{
  width: '200px';
  height: '1000px';
  border: '2px solid red';
  margin: 'auto';
  clip-path: 'polygon(6% 0, 100% 0, 100% 100%, 0 99%, 0 6%)';
  background-color: red;
} */

/* .hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
} */

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: black; 
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background: #06121c; 
  height:1px;
  width: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #06121c; 
  width: 2px;
}

.btn {
  transition: all .5s ease;
  text-align: center;
  background-color : transparent;
}
.btn2 {
  transition: all .5s ease;
  text-align: center;
  background-color : transparent;
}
.btn3 {
  transition: all .5s ease;
  text-align: center;
  background-color : transparent;
}
.btn:hover {
  color: #84c2de;
  border:1px solid #84c2de
}

.btn2:hover{
  color: #9F1E0B;
  border:1px solid #9F1E0B
}

.btn3:hover{
  color: #9F1E0B;
  border:2px solid #9F1E0B;
}

.patuaone{
  font-family: 'Patua One';
}


.our-blogs{
  background-image: url('../src/assets/images/red frame.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
}

.card .circle {
  background-color: #9f1e0b;
  border-radius: 50%;
}


.text-gradient {
  background-image: linear-gradient(to right, #FF6435 25%, #FFCEA4 50%, #FB9E87 75%, #F76C69 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.max-w-1440 {
  max-width: 1440px;
  margin: 0 auto;
}

.fontWeight{
  font-weight: 100;
}

.blackandwhiteimg{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  
}